import consumer from "./consumer"

consumer.subscriptions.create({ channel: "BuildLogChannel" }, {
  logContainer() { return $('#result'); },

  connected() {
    // if (cableLog) { return console.log('BuildLogChannel connected ...'); }
  },

  followBuild(build) {
    // if (cableLog) { console.log(`subscribed for build log ${build}`); }
    return this.perform('follow', {build_id: build});
  },

  received(data) {
    // if (cableLog) { console.log('receivedLogEvent', data); }

    if (data['event'] === 'log') {
      if (this.logTailInProgress) { return; }
      this.logTailInProgress = true;
      return this.fetchBuildLog(data['build_id']);
    } else if (data['event'] === 'log-tail') {
      this.appendBuildLog(data);
      return this.logTailInProgress = false;
    }
  },

  fetchBuildLog(build) {
    return this.perform('build_log_tail', {build_id: build, received: this.logContainer().data('received')});
  },

  appendBuildLog(data) {
    return $('#result').data('received', data['received']).append(data['tail']);
  }
});
