import moment from 'moment/moment'

document.addEventListener("turbolinks:load", function() {
  const refreshRunningTimer = function() {
    let build, started;
    const $runningBuilds = $('#builds .status-running');
    for (build of Array.from($runningBuilds)) {
      started = new Date(parseFloat($(build).data('started-at')));
      $(build).find('.duration').html(moment.utc(moment(new Date()).diff(moment(started))).format("mm:ss"));
    }

    const $allBuilds = $('#builds :not(.status-queued)[data-started-at]');
    return (() => {
      const result = [];
      for (build of Array.from($allBuilds)) {
        started = new Date(parseFloat($(build).data('started-at')));

        const startedAgo = moment(started).fromNow();
        if ($(build).data('startedAgo') !== startedAgo) {
          $(build).find('.started').html(moment(started).fromNow());
          result.push($(build).data('startedAgo', startedAgo));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  };

  setInterval(refreshRunningTimer, 1000);
  return refreshRunningTimer();
});
