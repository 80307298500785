import { Controller } from "stimulus"
import moment from "moment"

require("chartkick").use(require("highcharts"))

export default class extends Controller {
  static targets = [ "chart" ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get("url"))
      .then(response => response.json())
      .then(allTimes => {
        let point;
        const series = [];

        series.push({
          name: 'Run time',
          data: (((() => {
            const result = [];
            for (point of Array.from(allTimes)) {
              result.push({ run_at: point.run_at, y: point.times.run });
            }
            return result;
          })()))
        });

        series.push({
          name: 'Real time',
          data: (((() => {
            const result1 = [];
            for (point of Array.from(allTimes)) {
              result1.push({ run_at: point.run_at, y: point.times.real });
            }
            return result1;
          })()))
        });

        series.push({
          name: 'User time',
          data: (((() => {
            const result2 = [];
            for (point of Array.from(allTimes)) {
              result2.push({ run_at: point.run_at, y: point.times.user });
            }
            return result2;
          })()))
        });

        series.push({
          name: 'Sys time',
          data: (((() => {
            const result3 = [];
            for (point of Array.from(allTimes)) {
              result3.push({ run_at: point.run_at, y: point.times.sys });
            }
            return result3;
          })()))
        });

        $(this.chartTarget).highcharts({
          chart: {
            type: "area",
            zoomType: 'x'
          },

          title: {
            text: "Run time"
          },

          yAxis: {
            type: "datetime",
            title: {
              text: "Duration"
            },

            dateTimeLabelFormats: {
              second: '%H:%M:%S',
              minute: '%H:%M:%S',
              hour: '%H:%M:%S',
              day: '%H:%M:%S',
              week: '%H:%M:%S',
              month: '%H:%M:%S',
              year: '%H:%M:%S'
            },

            min: 0
          },

          tooltip: {
            formatter() {
              const duration = moment(this.point.y).format('mm:ss');

              return `Run at: <b>${new Date(this.point.run_at)}</b><br>${this.point.series.name}: <b>${duration}</b>`;
            }
          },

          series
        });
    })
  }
}
