import { Controller } from "stimulus"
import ExampleBuilder from '../src/example-builder'

export default class extends Controller {
  connect() {
    this.builder = new ExampleBuilder($('.build-examples'))

    this.load()

    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  load() {
    fetch(this.fetchURL({ last_example_id: this.builder.lastExampleID }), { headers: { "Accept": "application/json" } })
      .then(response => response.json())
      .then(build => {
        if (build.examples.length)
          this.builder.add(build.examples)

        if (build.finished && !build.examples.length)
          this.stopRefreshing()
      })
  }

  fetchURL(params) {
    let url = new URL(this.data.get("url"), window.location.origin)

    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

    return url
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.data.get("refreshInterval"))
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}
