import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["source"]

  copyBatch() {
    let path = this.sourceTarget.dataset.path

    Rails.ajax({
      type: "get",
      url: $('.build-examples').data('batch-url'),
      data: new URLSearchParams({ path: path }).toString(),
      success: function (data) {
        navigator.clipboard.writeText(data.command)
      }
    })
  }
}
