import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filter", "list"]

  connect() {
    $(this.element).on('shown.bs.dropdown', () => {
      this.filterTarget.value = '';
      this.filterTarget.focus();
    })
  }

  disconnect() {
    $(this.element).off('shown.bs.dropdown')
  }

  search() {
    this.listTarget.querySelectorAll('li:not(.divider)').forEach((li) => {
      const a = li.querySelector('a')

      if (!a) { return }

      const val = a.textContent || a.innerText;

      if (val.toUpperCase().indexOf(this.filterTarget.value.toUpperCase()) > -1) {
        li.style.display = "inline";
      } else {
        li.style.display = "none";
      }
    })
  }
}
