import { Controller } from "stimulus"
import * as Credential from "src/credential";

export default class extends Controller {
  static targets = ["usernameField"]

  create(event) {
    var [data, status, xhr] = event.detail;
    console.log(data);
    var credentialOptions = data;
    Credential.get(credentialOptions);
  }

  error(event) {
    let response = event.detail[0];

    alert(response["errors"])
    // let usernameField = new MDCTextField(this.usernameFieldTarget);
    // usernameField.valid = false;
    // usernameField.helperTextContent = response["errors"][0];
  }
}
