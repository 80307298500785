$(document).on('ajax:success', 'form[data-remote="true"]:not([data-skip-turbolinks])', function(event) {
  // const data = event.detail[0]
  // const status = event.detail[1]
  const xhr = event.detail[2];
  const {
    responseText
  } = xhr;

  if (responseText.substring(0, 10) !== 'Turbolinks') {
    let input;
    const formId = `#${this.id}`;
    $(formId).html(responseText);

    if (input = $('.has-error :input')[0]) {
      const offset = $(input).offset().top - 83; // remove header, but keep label
      window.scroll(0, offset);
      return input.focus();
    } else {
      return window.scroll(0, 0);
    }
  }
});
