import moment from 'moment/moment'

class ExampleBuilder {
  constructor(container) {
    this.container = container
    this.exampleGroups = {}
    this.lastExampleID = 1
  }

  add(examples) {
    console.time('Init examples')
    for (const example of examples) {
      this.createExample(example)
    }

    let lastExample = examples[examples.length - 1]
    this.lastExampleID = lastExample.id

    this.container.find('.example-group').trigger('refresh:group');
    console.timeEnd('Init examples')
  }

  createExample(example) {
    example.status_class = this.statusClass(example.status)
    let exampleGroup = this.getOrCreateExampleGroup(example.file_path)

    if (example.status == 'failed')
      exampleGroup.addClass('failed')

    example = this.createElement(this.renderExample(example))
    exampleGroup.find('.examples').append(example)
  }

  createExampleGroup(file_path) {
    this.exampleGroups[file_path] = this.createElement(this.renderGroup(file_path))
    this.container.append(this.exampleGroups[file_path])

    return this.exampleGroups[file_path]
  }

  getOrCreateExampleGroup(file_path) {
    if (this.exampleGroups[file_path])
      return this.exampleGroups[file_path]
    else
      return this.createExampleGroup(file_path)
  }

  createElement(innerHtml) {
    // return document.createRange().createContextualFragment(innerHtml)
    // let doc = document.createElement('div')
    // doc.innerHTML = innerHtml
    // return doc.firstChild
    return $(innerHtml)
  }

  statusClass(status) {
    switch (status) {
      case "passed":
        return 'success'
      case "failed":
        return 'danger'
      case "pending":
        return 'warning'
      default:
        return 'default'
    }
  }

  renderGroup(file_path) {
    return `<div class="example-group" data-file-path="${ file_path }">
      <div class="header">
        <div class="badges">
          <div class="label label-success passed"></div>
          <div class="failed label label-danger"></div>
          <div class="label label-warning pending"></div>
        </div>
        <div class="run-time"></div>
        <a href="#${ this.hashCode(file_path) }" class="group-link">${ file_path }</a>
        <a class="copy-batch" data-controller="batch" data-action="batch#copyBatch" data-target="batch.source" data-path="${ file_path }"></a>
        <a class="copy-to-clipboard" data-controller="clipboard" data-action="clipboard#copy" data-target="clipboard.source" data-text="bundle exec rspec ${ file_path }"></a>
      </div>

      <div class="examples" id="${ this.hashCode(file_path) }"></div>
    </div>`
  }

  renderExample(example) {
    return `<div class="example list-group-item-${ example.status_class }" data-id="${ example.id }" data-status="${ example.status_class }" data-run-time="${ example.run_time }">
      <div class="header">
        <div class="example-location"> ${ this.exampleDetails(example) }</div>
        <div class="example-description">${ example.description }</div>
        <div class="example-duration">${ example.run_time }s</div>
      </div>
    </div>`
  }

  exampleDetails(example) {
    if (example.status == 'failed') {
      return `<a class="load-example-details" href="#"> ${ example.location }</a>
       <a class="copy-to-clipboard" data-controller="clipboard" data-action="clipboard#copy" data-target="clipboard.source" data-text="bundle exec rspec '${ example.location }'"></a>`
    } else {
      return example.location
    }
  }

  hashCode(s) {
    return s.split('').reduce((function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }), 0);
  }
}

export default ExampleBuilder

document.addEventListener("turbolinks:load", function () {
  if (!$('.build-examples').length) { return; }

  $('.build-examples').on('refresh:group', '.example-group', function (e) {
    const $group = $(e.target);
    for (let status of ['success', 'danger', 'warning']) {
      const statusCount = $group.find(`.example[data-status='${status}']`).length;
      if (statusCount) { $group.find(`.label.label-${status}`).text(statusCount); }
    }

    const runTime = ($group.find('.example').map((i, e) => parseFloat($(e).data('run-time')))).get().reduce((s, t) => parseFloat(s) + parseFloat(t));

    const formatted = moment.utc(runTime.toFixed(2) * 1000).format('mm:ss');
    $group.find('.run-time').text(formatted);
  });

  $('.build-examples').on('click', '.example[data-status=danger] a.load-example-details', function (e) {
    e.preventDefault();
    const $example = $(e.target).closest('.example');
    if ($example.find('.example-details').length) {
      return $example.find('.example-details').toggle();
    } else {
      const buildID = $('.build').attr('id').replace('build_', '');

      return $.ajax({
        url: `/builds/${buildID}/examples/${$example.data('id')}`,
        data: {}
      });
    }
  });

  const filter = () => $('#filter_failed_only').parents('.build').toggleClass('failed-only', $('#filter_failed_only').prop('checked'));
  $('body').on('click', '#filter_failed_only', filter);
  filter();

  const anchor = window.location.hash;
  if (anchor) {
    $(".examples").hide();
    $(anchor).show();
  }

  $('body').on('click', '.example-group > .header > a.group-link', function (e) {
    $($(this).attr('href')).toggle();

    e.preventDefault();
    history.pushState({}, document.title, $(this).attr('href'));
  });
});
