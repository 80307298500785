import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.dataset.text).then(function() {
       // clipboard successfully set
    }, function() {
      /* clipboard write failed */
    });
  }
}
