import { Controller } from "stimulus"
import Chartkick from "chartkick"
import Highcharts from "highcharts"

Chartkick.use(Highcharts)

export default class extends Controller {
  connect() {
    new Chartkick.AreaChart(this.element, this.data.get("data"), JSON.parse(this.data.get("options") || {}))
  }
}
