/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'jquery'
window.jQuery = window.$ = require('jquery')

import 'bootstrap'
import('src/plugins')
// import('src/timer')
import 'src/timer'
import 'src/remote-form'

import "controllers"

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")

// console.log($)

document.addEventListener("turbolinks:load", function() {
  if (("standalone" in window.navigator) && window.navigator.standalone) {
    return $("a").on("click", function(e) {
      // console.log('boo');
      e.preventDefault();
      const new_location = $(this).attr("href");
      if ((new_location != null) && (new_location.substr(0, 1) !== "#") && ($(this).attr("data-method") == null) && ($(this).attr("data-remote") == null)) { return window.location = new_location; }
    });
  }
});

document.addEventListener("turbolinks:load", function() {
  $('[data-toggle="tooltip"]').tooltip();
});
